<template>
    <div class="row">
        <div class="col-12 mb-3">
            <h3 class="text-muted">{{ device.device_name }} : <span id="lastActivityCountdown">{{ lastActivityCountdown() }}</span></h3>
        </div>

        <b-tabs v-model="currentTab" class="col-12" active-nav-item-class="custom-tabs" @input="updateCurrentTab">
            <b-tab title="Aperçu" active lazy>
                <devices-overview
                    :tenant="tenant"
                    :device="device"
                    :is-admin="isAdmin"
                    :application="application"
                    :currentTab="currentTab"
                    @change-tab="setNewTab"
                />
            </b-tab>
            <b-tab title="Données" lazy>
                <datas-show 
                    :tenant="tenant" 
                    :device="device" 
                    :is-admin="isAdmin" 
                    :application-id="application.id" 
                    :currentTab="currentTab"
                />
            </b-tab>
            <b-tab v-if="isFullTenant" title="Paramètres" lazy>
                <devices-setting 
                    :tenant="tenant" 
                    :device="device"
                    :currentTab="currentTab" 
                />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    export default {        
        props: {
            isFullTenant: Number,
            tenant: Object,
            device: Object,
            isAdmin: Number,
            application: {
                required: true,
                type: Object
            },
            lastActivity: {
                required: true,
                type: [Object, Number]
            }
        },

        data() {
            return {
                currentTab: 0
            }
        },

        methods: {
            lastActivityCountdown(){
                var label = ''

                const lastActivityId = setInterval(() => {
                    var now = new Date().getTime()
                    var lastActivity = this.lastActivity.date
                    var distance = now - new Date(lastActivity).getTime()

                    var days = Math.floor(distance / (1000 * 60 * 60 * 24))
                    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000)

                    if(days > 0 && days <= 7){
                        label = `Dernière activité il y a ${days}j ${hours}h`
                    }else if(days > 7){
                        if(new Date(lastActivity).getMonth()+1 < 10) {
                            var month = '0' + (new Date(lastActivity).getMonth()+1)
                        }else{
                            var month = new Date(lastActivity).getMonth()+1
                        }

                        var date = new Date(lastActivity).getDate() + '/' + month + '/' + new Date(lastActivity).getFullYear()
                        label = `Dernière activité le ${date}`
                    }else if(hours > 0 && days == 0){
                        label = `Dernière activité il y a ${hours}h ${minutes}min`
                    }else if(minutes > 0 && hours == 0 && days == 0){
                        label = `Dernière activité il y a ${minutes}min `
                    }else if(seconds > 0 && minutes == 0 && hours == 0 && days == 0){
                        label = `Dernière activité il y a ${seconds}s`
                    }else{
                        label = "Pas d'activité récente"
                    }

                    if(this.lastActivity == 0){
                        clearInterval(lastActivityId)
                        label = "Pas d'activité récente"
                    }

                    document.getElementById("lastActivityCountdown").innerHTML = label
                }, 1000)
            },

            updateCurrentTab(newTab) {
                this.currentTab = newTab
            },

            setNewTab(newTab) {
                this.currentTab = newTab
            }
        }
    }
</script>

<style>
    #lastActivityCountdown {
        font-size: 0.9rem;
    }

    .custom-tabs .nav-tabs .nav-items {
        border-radius: 20px 20px 0 0 !important;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
</style>

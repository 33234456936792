<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div :class="nbApp || index != 0 ? 'card rounded-card-1' : 'card rounded-card-0'">
                    <div class="card-body">
                        <div v-if="application.devices.length !== 0">
                            <b-alert v-if="success !== null" variant="success" dismissible>{{ success }}</b-alert>
                            <b-alert v-else-if="error !== null" variant="danger" dismissible>{{ error }}</b-alert>

                            <button v-if="dragMode" @click="saveOrder">Enregistrer l'ordre</button>

                            <b-dropdown v-if="!isFullTenant"  variant="transparent" text="Options" toggle-class="text-decoration-none" no-caret class="float-right">
                                <template #button-content>
                                    <i class="bi bi-three-dots-vertical"></i>
                                </template>
                                <b-dropdown-item @click="toggleDragMode">
                                    <span v-if="dragMode" class="ml-2">&#10003;</span>
                                    Activer le mode organiser
                                </b-dropdown-item>
                            </b-dropdown>
                            <draggable v-bind="dragOptions" :list="dashboardBlocsOrder" @update="onBlocksChange" class="row">
                                <component v-for="order in dashboardBlocsOrder" 
                                    :key="`order-${order.name}`" 
                                    :is="`dashboard-blocs-${order.name}`" 
                                    :application="application"
                                >
                                </component>
                            </draggable>

                            <scroll-top></scroll-top>

                        </div>
                        <div v-else>
                            <div class="row d-flex justify-content-center">
                                <div class="alert alert-danger p-4" role="alert">
                                    Vous n'avez pas de capteurs. 
                                    Vous pouvez en ajouter <a :href="`/tenants/${application.tenant_id}/devices/create`">ici</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    props: {
        application: {
            type: Object,
            required: true
        },
        nbApp: {
            type: Boolean,
            required: true
        },
        index: {
            type: Number,
            required: false
        },
	isFullTenant: {
	    type: Number,
	    required: false
	}
    },

    data () {
        return {
            dashboardBlocsOrder: [],
            defaultBlocsOrder: [
                {
                    name: 'list',
                    order: 0
                },
                {
                    name: 'levels',
                    order: 1
                },
                {
                    name: 'states',
                    order: 2
                },
                {
                    name: 'alarms',
                    order: 3
                }
            ],
            dragMode: false,
            success: null,
            error: null
        }
    },

    computed: {
        dragOptions() {
            return {
                animation: 150,
                disabled: !this.dragMode
            }
        }
    },

    mounted () {
        this.initSettings()
    },

    methods: {
        initSettings () {
            if (!this.isFullTenant && this.application.users[0].pivot.dashboardBlocsOrder !== null) {
                this.dashboardBlocsOrder = this.application.users[0].pivot.dashboardBlocsOrder
                    .slice()
                    .sort((a, b) => a.order - b.order)
            } else {
                this.dashboardBlocsOrder = this.defaultBlocsOrder.slice()
            }
        },

        toggleDragMode() {
            this.dragMode = !this.dragMode
        },

        saveOrder() {
            axios.post(`/api/tenants/${this.application.tenant_id}/applications/${this.application.id}/dashboardBlocsOrder`, {
                dashboardBlocsOrder: this.dashboardBlocsOrder
            })
            .then(response => {
                this.dragMode = !this.dragMode
                this.success = response.data.message
            })
            .catch(error => {
                this.error = "Une erreur s'est produite. Veuillez recharger la page et réessayer d'enregistrer l'ordre"
            })
        },
        onBlocksChange() {
            this.dashboardBlocsOrder = [...this.dashboardBlocsOrder].map((order, index) => {
                return {...order, order: index}
            })
        }
    }
}
</script>

<style>
    .rounded-card-0 {
        border-radius: 0 20px 20px 20px;
        box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.1);
    }

    .rounded-card-1 {
        border-radius: 20px;
        box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.1); 
    }
</style>

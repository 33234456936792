<template>
    <div class="row">
        <div class="col-12">
            <div :class="currentTab != 0 ? 'card rounded-card-1' : 'card rounded-card-0'">
                <div class="card-body">
					<h3 class="mt-3 mb-4">Données de l'application {{ application.application_name }}</h3>

					<b-alert show dismissible v-if="pdfError" variant="danger">{{ pdfError }}</b-alert>

					<b-form-group v-if="totalRows != 0" label="Nombre de lignes" label-for="nb-per-page" label-cols-lg="2">
						<b-form-select
							id="nb-per-page"
							class="nb-per-page col-lg-3"
							v-model="perPage"
							:options="perPageOptions"
						>
						</b-form-select>
					</b-form-group>

					<b-pagination
						v-if="totalRows != 0"
						v-model="currentPage"
						:total-rows="totalRows"
						:per-page="perPage"
						aria-controls="dataTable"
						first-number
						last-number
					></b-pagination>

					<div v-if="totalRows != 0" class="mt-3 mb-3">
						<b-button-group>
							<b-button type="submit" variant="outline-dark" @click="createPdf()">
								<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
								</svg>
							</b-button>
							<b-button type="submit" variant="outline-dark" @click="createCsv()">
								<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-filetype-csv" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"/>
								</svg>
							</b-button>
						</b-button-group>
					</div>

					<b-table 
						:id="`datas-table-application-${application.id}`"
						hover
						responsive
						bordered
						:per-page="perPage"
						:currentPage="currentPage"
						:fields="allFields()"
						:items="datasProvider"
						:busy.sync="isBusy"
					>
						<template v-if="totalRows != 0" #table-busy>
							<div class="text-center text-danger my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Chargement...</strong>
							</div>
						</template>

						<template #cell(device.device_name) = "data">
							<b-link :href="`/tenants/${tenant.id}/devices/${data.item.device.id}`">{{ data.item.device.device_name }}</b-link>
						</template>
						<template #cell(battery) = "{ value }">
							{{ value }}%
						</template>
						<template #cell(temperature) = "{ value }">
							<template v-if="value != '' || value === 0">
								{{ value }}°C
							</template>
						</template>
						<template #cell(opening_status_open) = "{ value }">
							<template v-if="value === 1">
								Ouvert
							</template>
							<template v-else-if="value === 0">
								Fermé
							</template>
						</template>
						<template #cell(opening_status_time) = "{ value }">
							<template v-if="value != '' || value === 0">
								{{ value }} min
							</template>
						</template>
						<template #cell(room_status_occupied) = "{ value }">
							<template v-if="value === 1">
								Occupé
							</template>
							<template v-else-if="value === 0">
								Vide
							</template>
						</template>
						<template #cell(room_status_time) = "{ value }">
							<template v-if="value != '' || value === 0">
								{{ value }} min
							</template>
						</template>
						<template #cell(rate) = "{ value }">
							<template v-if="value != '' || value === 0">
								{{ value }}%
							</template>
						</template>
						<template #cell(device.location) = "data">
							{{ data.item.device.location }}
						</template>
						<template #cell(device.container_type) = "data">
							{{ data.item.device.container_type }}
						</template>
						<template #cell(device.container_characteristic) = "data">
							{{ data.item.device.container_characteristic }}
						</template>
					</b-table>

					<b-pagination
						v-if="totalRows != 0"
						v-model="currentPage"
						:total-rows="totalRows"
						:per-page="perPage"
						aria-controls="dataTable"
						first-number
						last-number
					></b-pagination>

					<div v-if="totalRows == 0" class="row d-flex justify-content-center">
                        <div class="alert alert-danger p-4" role="alert">
                            {{ noData }}
                        </div>
                    </div>
				</div>
            </div>
        </div>
    </div> 
</template>

<script>
	export default {
		props: {
			tenant: {
				required: true,
				type: Object
			},
			application: {
				required: true,
				type: Object
			},
			isAdmin: Number,
			isFullTenant: Number,
			currentTab: {
				required: true,
				type: Number
			}
		},

		data() {
			return {
				fields: [
					{ key: 'device.device_name', label: 'Capteur', visible: true },
					{ key: 'date', sortable: true, label: 'Date', visible: true, formatter: value => {
						const date = new Date(value)
						function addZero(i) {
							if (i < 10) {i = "0" + i}
							return i;
						}
						let formatted_date = addZero(date.getDate()) + "/" + addZero(date.getMonth() +1) + "/" + date.getFullYear() + " - " + addZero(date.getHours()) + ":" + addZero(date.getMinutes()) + ":" + addZero(date.getSeconds())

						return formatted_date;
						}
					},
					{ key: 'battery', sortable: true, label: 'Batterie', visible: true },
					{ key: 'temperature', label: 'Température', visible: true },
					{ key: 'opening_status_open', label: 'État Porte', visibleDoor: true },
					{ key: 'opening_status_count', label: 'Compteur Porte', visibleDoor: true },
					{ key: 'opening_status_time', label: 'Temps Porte', visibleDoor: true },
					{ key: 'room_status_occupied', label: 'État Mouvement', visibleMotion: true },
					{ key: 'room_status_count', label: 'Compteur Mouvement', visibleMotion: true },
					{ key: 'room_status_time', label: 'Temps Mouvement', visibleMotion: true },
					{ key: 'device.location', label: 'Localisation', visibleLevel: true },
					{ key: 'rate', sortable: true, label: 'Taux de remplissage', visibleLevel: true },
					{ key: 'device.container_type', label: 'Contenant', visibleLevel: true },
					{ key: 'device.container_characteristic', label: 'Contenu', visibleLevel: true },
				],

				perPage: 10,
				perPageOptions: [
					10, 25, 50, 75, 100
				],
				totalRows: 0,
				currentPage: 1,
				isBusy: false,
				noData: '',
				intervalId: null,

				fieldsExport: [],
				itemsExport: [],
				pdfError: ''
			}
		},

		mounted(){
			const self = this
			this.intervalId = setInterval(function() {
				self.$root.$emit('bv::refresh::table', `datas-table-application-${self.application.id}`)
			}, 10000)
		},

		beforeDestroy(){
			clearInterval(this.intervalId)
		},

		methods: {
			datasProvider(ctx) {
				const promise = axios.get(`/api/tenants/${this.tenant.id}/applications/${this.application.id}/datas`, {
					params: {
						per_page: ctx.perPage,
						page: ctx.currentPage,
						sort_by: ctx.sortBy,
						sort_desc: ctx.sortDesc
					}
				})

				return promise.then((response) => {
					const items = response.data.data
					this.totalRows = response.data.total
					this.itemsExport = response.data.data

					if (this.totalRows === 0) {
						this.noData = "Vous n'avez pas de données"
					}

					return items || []
				})
				.catch(() => {
					return []
				})
			},

			allFields() {
				let visibleFields = []

				this.application.device_types.forEach((deviceType) => {
					if (deviceType.type ===  'Porte') {
						this.fields.forEach((field, index) => {
							if (field.visible || field.visibleDoor) {
								visibleFields.push({ ...field })
							}
						})
					} else if (deviceType.type === 'Mouvement') {
						this.fields.forEach((field, index) => {
							if (field.visible || field.visibleMotion) {
								visibleFields.push({ ...field })
							}
						})
					} else if (deviceType.type === 'Niveau') {
						this.fields.forEach((field, index) => {
							if ((field.visible || field.visibleLevel) && field.key !== 'temperature') {
								visibleFields.push({ ...field })
							}
						})
					}
				})

				this.fieldsExport = visibleFields

				return visibleFields
			},

			createPdf() {
				axios.post(`/tenants/${this.tenant.id}/applications/${this.application.id}/save-pdf/datas`, {
					fieldsExport: this.fieldsExport,
					itemsExport: this.itemsExport
				})
				.then((response) => {
					axios.get(`/tenants/${this.tenant.id}/applications/${this.application.id}/download-pdf/datas?filename=${response.data}`, {
						headers: {
							Accept: 'application/pdf'
						},
						responseType: 'blob'
					})
					.then((response) => {
						if(response.data.size != 0){
							const url = window.URL.createObjectURL(new Blob([response.data], { type : 'application/pdf '}));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', response.headers['content-disposition'].substring(21));
							document.body.appendChild(link);
							link.click();
						}else{
							this.pdfError = 'Une erreur est survenue lors du téléchargement du pdf. Veuillez réessayer';
						}
					})
					.catch((error) => {
						// console.log(error)
					})
				})
				.catch((error) => {
					this.pdfError = 'Une erreur est survenue lors du téléchargement du pdf. Veuillez réessayer';
				});
			},

			createCsv(){
				axios.post(`/tenants/${this.tenant.id}/applications/${this.application.id}/save-csv`, {
					itemsExport: this.itemsExport
				})
				.then((response) => {
					axios.get(`/tenants/${this.tenant.id}/applications/${this.application.id}/download-csv?filename=${response.data}`, { responseType: 'blob' })
					.then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', response.headers['content-disposition'].substring(21));
						document.body.appendChild(link);
						link.click();
					})
					.catch((error) => {
						console.error('Erreur lors du téléchargement du CSV', error);
					})
				})
				.catch((error) => {
					console.error('Erreur !!', error);
				})
			}
		}
	}
</script>

<style>
	.rounded-card-0 {
        border-radius: 0 20px 20px 20px;
        box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.1);
    }

	.rounded-card-1 {
        border-radius: 20px;
        box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.1);
    }
</style>
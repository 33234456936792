<template>
    <div>
        <div class="row">
            <div class="col-12 mb-3">
                <h3 class="text-muted">Tableau de bord</h3>
            </div>
        </div>

        <template v-if="tenant.applications.length === 0">
            <div class="row d-flex justify-content-center">
                <!-- A changer isFullTenant par admin -->
                <div v-if="isFullTenant !== 1" class="alert alert-danger p-4" role="alert">
                    Vous n'avez pas d'application. 
                    Veuillez nous contacter à l'adresse mail : 
                    <a href="mailto:contact@octosystem.eu?subject=Problème d'application">
                        contact@octosystem.eu
                    </a>
                </div>
                <div v-else class="alert alert-danger p-4" role="alert">
                    Vous n'avez pas d'application. 
                    Veuillez en créer une <a :href="`/tenants/${tenant.id}/applications/create`">ici</a>
                </div>
            </div>
        </template>

        <template v-else>
            <template v-if="tenant.applications.length === 1">
                <template v-if="tenant.applications[0].devices.length === 0">
                    <div class="row d-flex justify-content-center">
                        <div class="alert alert-danger p-4" role="alert">
                            Vous n'avez pas de capteurs. 
                            Vous pouvez en ajouter <a :href="`/tenants/${tenant.id}/devices/create`">ici</a>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <dashboard-tab 
			:application="tenant.applications[0]"
			:nbApp=true
			:isFullTenant="isFullTenant"
		     />
                </template>
            </template>

            <template v-else>
                <ul class="nav nav-tabs" id="applicationsTab" role="tablist">
                    <li v-for="(application, index) in tenant.applications" :key="index" class="nav-item mr-2">
                        <a
                        :class="['nav-link', { active: activeTab === index }, 'custom-tabs']"
                        @click="changeTab(index)"
                        data-toggle="tab"
                        :href="'#application-' + application.id + '-dashboard'"
                        role="tab"
                        >
                            Tableau de bord de l'application {{ application.application_name }}
                        </a>
                    </li>
                </ul>

                <div class="tab-content" id="applicationsTabContent">
                    <dashboard-tab
                        v-for="(application, index) in tenant.applications"
                        :key="index"
                        :class="['tab-pane', { active: activeTab === index }]"
                        :id="'application-' + application.id + '-dashboard'"
                        role="tabpanel"
                        :application="application"
                        :nbApp=false
                        :index="index"
			:isFullTenant="isFullTenant"
                    />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            tenant: {
                type: Object,
                required: true
            },
            isFullTenant: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                activeTab: 0
            }
        },

        methods: {
            changeTab(index) {
                this.activeTab = index
            }
        }
    }
</script>

<style>
    .custom-tabs {
        border-radius: 20px 20px 0 0 !important;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
</style>
